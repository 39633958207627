import $ from "jquery";
import _ from "underscore";

var prevTabIndexElm;

export default class ModalLib {
  constructor() {
    this.body = document.getElementsByTagName("body")[0];
    this.modal = document.getElementById("modal");
  }

  openModal(id, fixed) {
    fixed = fixed || false;
    const d = $.Deferred();

    // 別のモーダル・ダイアログが開いていた場合は閉じる
    const innerElm = this.modal.getElementsByClassName("modal__inner");
    Array.prototype.forEach.call(innerElm, elm => {
      const id2 = elm.dataset.modal;
      if (id != id2) {
        elm.classList.remove("-show");
      }
    });

    const dialogElm = this.modal.getElementsByClassName("modal__dialog");
    Array.prototype.forEach.call(dialogElm, elm => {
      const id2 = elm.dataset.modal;
      if (id != id2) {
        elm.classList.remove("-show");
      }
    });

    const targetElm = document.getElementById(id);
    $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
    if (targetElm) {
      prevTabIndexElm = document.activeElement;
      this.body.classList.add("-modalOpen");
      if (fixed) {
        this.body.classList.add("-modalOpenFixed");
      }
      targetElm.classList.add("-show");
      this.modal.classList.add("-visible");
      $(this.modal).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
        $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
        targetElm.focus();
        d.resolve(targetElm);
      });
      _.delay(() => {
        this.modal.classList.add("-show");
      }, 100);
    } else {
      d.reject();
    }

    return d.promise();
  }

  closeModal(scrollPosY, fixed) {
    scrollPosY = scrollPosY || 0;
    fixed = fixed || false;
    const d = $.Deferred();
    prevTabIndexElm.focus();

    this.modal.classList.remove("-show");
    $(this.modal).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
      this.body.classList.remove("-modalOpen");
      this.body.classList.remove("-modalOpenFixed");
      if (fixed) {
        window.scrollTo(0, scrollPosY);
      }
      this.modal.classList.remove("-visible");
      const innerElm = this.modal.getElementsByClassName("modal__inner");
      Array.prototype.forEach.call(innerElm, elm => {
        elm.classList.remove("-show");
      });
      const dialogElm = this.modal.getElementsByClassName("modal__dialog");
      Array.prototype.forEach.call(dialogElm, elm => {
        elm.classList.remove("-show");
      });
      $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
      d.resolve();
    });

    return d.promise();
  }
}
