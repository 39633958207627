/**
 * ROCが利用するセッションストレージを管理する.
 *
 * 必要となった背景：
 * CMS資材からAndroidアプリ(v.2.1.7)の履歴情報にアクセスが出来ず、
 * ブラウザバックやURLパラメーターの変化を検知出来ない.
 * History APIを用いた実装が理想だが、画面描画に必要な情報をセッションストレージに保存して活用する改修を行った.
 *
 * https://redmine.bookoff.org/redmine/issues/44176
 * https://yafuoff.backlog.jp/view/BOC_ROC_FRONT-4477
 */
export default class RocSessionStorage {
  constructor() {
    this.rocSessionKey = 'roc-session';
  }

  load(sessionKey) {
    if (!sessionKey) {
      return {};
    }
    var sessionJson = window.sessionStorage.getItem(this.rocSessionKey);
    if (!sessionJson) {
      return {};
    }
    var sessionJsonObject = JSON.parse(sessionJson);
    if (!sessionJsonObject || Object.keys(sessionJsonObject).length === 0) {
      return {};
    }
    return sessionJsonObject[sessionKey] || {};
  }

  save(sessionKey, jsonObject) {
    if (!sessionKey || !jsonObject || Object.keys(jsonObject).length === 0) {
      return;
    }
    var sessionJson = window.sessionStorage.getItem(this.rocSessionKey);
    var sessionJsonObject = sessionJson ? JSON.parse(sessionJson) : {};
    sessionJsonObject[sessionKey] = jsonObject;
    window.sessionStorage.setItem(this.rocSessionKey, JSON.stringify(sessionJsonObject));
  }

  clear(sessionKey) {
    if (!sessionKey) {
      return;
    }
    var sessionJson = window.sessionStorage.getItem(this.rocSessionKey);
    var sessionJsonObject = sessionJson ? JSON.parse(sessionJson) : {};
    if (!sessionJsonObject || Object.keys(sessionJsonObject).length === 0) {
      return;
    }
    delete sessionJsonObject[sessionKey];
    window.sessionStorage.setItem(this.rocSessionKey, JSON.stringify(sessionJsonObject));
  }
}
