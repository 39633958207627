import $ from "jquery";

export default class Orientation {
  constructor() {
    this.bind();
  }

  bind() {
    $('.js-orientation').each(function(){
      const $target = $(this);
      var img = $('<img>');
      img.attr('src', $target.attr('src'));
      img.on('load', function() {
        const r = $target.width() / $target.height();
        if(r>=1){
          $target.addClass('-ls'); //横長or正方形
        }else{
          $target.addClass('-pt'); //縦長
        }
      });
    });
  }
}
