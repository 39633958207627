import $ from "jquery";
import _ from "underscore";

export default class RadioReceivingMethod {
  constructor() {
    this.init();
  }

  init() {
    this.bindEvents();
    this.checkRadioBtn();
  }

  bindEvents() {
    const radioBtn = document.getElementsByClassName('js-radioReceivingMethod');

    this.debouncedOnRadioBtnClick = _.debounce(elm => {
      this.checkRadioBtn();
    });

    if (radioBtn.length) {
      Array.prototype.forEach.call(radioBtn, elm => {
        elm.addEventListener("click", e => {
          this.debouncedOnRadioBtnClick(elm);
        });
      });
    }
  }

  checkRadioBtn() {
    const radioBtn = document.getElementsByClassName('js-radioReceivingMethod');
    let checkedFlg = false;
    let addressFlg = false;
    let storeFlg = false;

    Array.prototype.forEach.call(radioBtn, elm => {
      const type = elm.getAttribute('data-type');
      if (elm.checked) {
        checkedFlg = true;
        if (type == "store") {
          storeFlg = true;
        }
        if (type == "address") {
          addressFlg = true;
        }
      }
    });
    if (checkedFlg) {
      if (storeFlg) {
        $('.js-radioReceivingMethodShowStore').removeClass('-hide');
      } else {
        $('.js-radioReceivingMethodShowStore').addClass('-hide');
      }
      if (addressFlg) {
        $('.js-radioReceivingMethodShowAddress').removeClass('-hide');
      } else {
        $('.js-radioReceivingMethodShowAddress').addClass('-hide');
      }
    } else {
      $('.js-radioReceivingMethodShowStore').addClass('-hide');
      $('.js-radioReceivingMethodShowAddress').addClass('-hide');
    }
  }
}
