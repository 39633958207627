import $ from "jquery";

export default class Tips {
  constructor() {
    this.bind();
  }

  bind() {
    const self = this;
    $(".js-tips").each(function(){
      const $btn = $(this).find(".js-tips-btn");
      const $balloon = $(this).find(".js-tips-balloon");
      $btn.on("click", function(){
        self.openTips($balloon);
      });
      $(this).keypress(function(e) {
        if (e.keyCode === 13) {
          self.openTips($balloon);
        }
      });
    });
  }

  openTips($balloon) {
    $balloon.fadeIn(300, function(){
      setTimeout(function(){
        $balloon.fadeOut(300);
      }, 5000);
    });
  }
}
