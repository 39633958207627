import $ from "jquery";

export default class Categorymore {
  constructor() {
    this.init();
  }

  init() {
    this.bind();
  }

  bind() {
    const self = this;
    $(".js-categorymore").each(function(){
      if($(this).find('li').length>6){
        $(this).append('<p class="refinement__categoryMore"><a class="link link--arrow js-categorymore-btn">もっと見る</a></p>');
        //setTimeout(() => {
          self.setTabIndex();
          self.open();
        //}, 100);
      }
    });
  }
  setTabIndex() {
    $(".js-categorymore").each(function(){
      const $btn = $(this).find(".js-categorymore-btn");
      $btn.attr('tabindex', 0);
    });
  }

  open() {
    $(".js-categorymore").each(function(){
      const $this = $(this);
      const $btn = $this.find(".js-categorymore-btn");
      $btn.on("click", function(){
        console.log(0);
        $this.addClass("-active");
      });
      $btn.keydown(function(e){
        if (e.keyCode == 13) {
          $this.addClass("-active");
        }
      });
    });
  }
}
