import "./polyfill";
import "./jquery-imageready";
import "what-input";

import "./modules/";

import SetViewport from "./modules/SetViewport";
import RankingOfWeekTab from "./modules/RankingOfWeekTab";

/** START_PRD_ONLY_BLOCK */
//urlにecdがあったときにwwwに変換する
(function () {
    $(function() {
      const $urlList = $("a[href*='ecd.']");
      $urlList.each(function(i, elm) {
      elm.href = elm.href.replace("ecd.", "www.");
      });
    });
  }());
/** END_PRD_ONLY_BLOCK */

// SetViewport
new SetViewport();

// RankingOfWeekTab
window.rankingOfWeekTab = new RankingOfWeekTab();
