/**
 * @license MIT https://github.com/weblinc/jquery-imageready
 */
import $ from "jquery";

$.fn.imageready = function(callback, userSettings) {
  const options = $.extend({}, $.fn.imageready.defaults, userSettings),
    $images = this.find("img").add(this.filter("img"));
  let unloadedImages = $images.length;

  if (callback == null) {
    callback = function() {};
  }

  function loaded() {
    unloadedImages--;
    !unloadedImages && callback();
  }

  function bindLoad() {
    let isIE;

    this.one("load", loaded);
    isIE = navigator.appName.indexOf("Internet Explorer") !== -1;

    if (isIE) {
      var src = this.attr("src"),
        param = src.match(/\?/) ? "&" : "?";

      param += options.cachePrefix + "=" + new Date().getTime();
      this.attr("src", src + param);
    }
  }

  return $images.each(function() {
    const $this = $(this);
    if (!$this.attr("src")) {
      loaded();
      return;
    }
    this.complete || this.readyState === 4 ? loaded() : bindLoad.call($this);
  });
};

$.fn.imageready.defaults = {
  cachePrefix: "random"
};
