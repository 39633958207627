import $ from "jquery";

export default class ArrangeHeight {
  constructor() {
    this.root = '.js-arrangeHeight';
    this.target = '.js-arrangeHeightTarget';
    this.mq = window.matchMedia('print, (min-width: 768px)');
    this.init();
  }
  init() {
    this.bind();
    this.resize();
  }
  bind() {
    if (this.mq.matches) this.setHeight();
  }
  resize() {
    const self = this;
    $(window).on('', function(){

      if (!self.mq.matches) {
        // reset
        $(self.target).css('height', '');
      } else {
        self.setHeight();
      }
    });
  }
  setHeight() {
    const self = this;
    $(self.root).each(function () {
      var $this = $(this);

      var images = function () {
        var list = [];
        $this.find('img').each(function () {
          list.push($(this).attr('src'));
        });
        return list;
      }();

      $this.find(self.target).css('height', 'auto');

      if (images.length) {
        // 内部に画像がある場合は画像全ての読み込みが完了したら高さ調整
        var imgLoaded = function imgLoaded() {
          var maxHeight = 0;

          $this.find(self.target).each(function () {
            if (maxHeight < $(this).outerHeight()) maxHeight = $(this).outerHeight();
          });

          $this.find(self.target).css('height', maxHeight);
        };

        self.imgLoad(imgLoaded, images);
      } else {
        var maxHeight = 0;

        setTimeout(function () {
          $this.find(self.target).each(function () {
            if (maxHeight < $(this).outerHeight()) maxHeight = $(this).outerHeight();
          });

          $this.find(self.target).css('height', maxHeight);
        }, 60);
      }
    });
  }

  imgLoad(callback, images) {
    if (typeof callback === 'function') {
      var preload = function preload() {
        var promises = [],
            retDefer = $.Deferred(),
            imgs = $.map(images, function (val) {
          return val;
        });

        $.each(imgs, function () {
          var img = new Image(),
              defer = $.Deferred();

          img.onload = function () {
            defer.resolve();
            defer = null;
          };
          img.onerror = function () {
            defer.reject();
            defer = null;
          };
          img.src = this;
          promises.push(defer.promise());
        });

        $.when.apply(null, promises).done(function () {
          retDefer.resolve();
        });

        $.when.apply(null, promises).fail(function () {
          retDefer.reject();
        });
        return retDefer.promise();
      };

      var promise;

      promise = preload(images);
      promise.done(function () {
        callback();
      });
      promise.fail(function () {
        callback();
      });
    }

  }
}
