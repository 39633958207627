import $ from "jquery";

export default class AllCheck {
  constructor() {
    this.bind();
  }

  bind() {
    const self = this;
    $(".js-allCheck").each(function(){
      const $this = $(this);
      const $trigger = $this.find(".js-allCheckTrigger");
      const $boxes = $this.find(".js-allCheckBoxes");
      const $target = $this.find(".js-allCheckTarget");
      $trigger.on('click', function() {
        $trigger.prop('checked', this.checked);
        $target.prop('checked', this.checked);
      });
      $target.on('click', function() {
        self.check($trigger,$boxes,$target);
      });
    });
  }

  check($trigger,$boxes,$target) {
    if ($boxes.find(':checked').length == $target.length) {
      $trigger.prop('checked', true);
    } else {
      $trigger.prop('checked', false);
    }

  }

}
