import _ from "underscore";
import $ from "jquery";

export default class CartQuantity {
  constructor() {
    this.container = document.getElementsByClassName('js-cartItem');
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.debouncedOnClickMinus = _.debounce((container, elm) => {
      const numElm = elm.getElementsByClassName('js-cartQuantityNum')[0];
      const num = parseInt(numElm.innerText, 10);
      const input = elm.getElementsByTagName('input')[0];

      elm.classList.remove('-max');
      $(container).find('.js-stockErrorMsg').removeClass('-active');

      if (num - 1 >= 0) {
        numElm.innerText = num - 1;
        if (input) {
          input.value = num - 1;
        }
      }
    }, 0);

    this.debouncedOnClickPlus = _.debounce((container, elm) => {
      const numElm = elm.getElementsByClassName('js-cartQuantityNum')[0];
      const num = parseInt(numElm.innerText, 10);
      const input = elm.getElementsByTagName('input')[0];
      const max = parseInt(elm.getAttribute('data-max'), 10) || '';

      if (!max) {
        numElm.innerText = num + 1;
        if (input) {
          input.value = num + 1;
        }
      } else {
        if (num >= max) {
          return false;
        }

        if (num + 1 >= max) {
          elm.classList.add('-max');
          $(container).find('.js-stockErrorMsg').addClass('-active');
        }

        numElm.innerText = num + 1;
        if (input) {
          input.value = num + 1;
        }
      }
    }, 0);

    Array.prototype.forEach.call(this.container, elm => {
      // + - 数量変更ボタン処理
      const quantityElm = elm.getElementsByClassName('js-cartQuantity')[0];
      if (quantityElm) {
        const minusElm = elm.getElementsByClassName('js-cartQuantityMinus')[0];
        const plusElm = elm.getElementsByClassName('js-cartQuantityPlus')[0];

        if (minusElm) {
          minusElm.addEventListener("click", e => {
            e.preventDefault();
            e.stopPropagation();
            this.debouncedOnClickMinus(elm, quantityElm);
          });
          minusElm.addEventListener("keypress", e => {
            // Enter
            if (e.keyCode === 13) {
              this.debouncedOnClickMinus(elm, quantityElm);
            }
          });
        }

        if (plusElm) {
          plusElm.addEventListener("click", e => {
            e.preventDefault();
            e.stopPropagation();
            this.debouncedOnClickPlus(elm, quantityElm);
          });
          plusElm.addEventListener("keypress", e => {
            // Enter
            if (e.keyCode === 13) {
              this.debouncedOnClickPlus(elm, quantityElm);
            }
          });
        }
      }

      // checkbox
      const cartItemCheckbox = elm.getElementsByClassName('js-cartItemCheckbox')[0];
      const cartSetItemCheckbox = elm.getElementsByClassName('js-cartSetItemCheckbox');
      if (cartItemCheckbox) {
        cartItemCheckbox.addEventListener("change", e => {
          if (cartSetItemCheckbox.length) {
            Array.prototype.forEach.call(cartSetItemCheckbox, elm => {
              if (e.target.checked) {
                elm.checked = true;
              } else {
                elm.checked = false;
              }
            });
          }
        });
      }
    });
  }
}
