import _ from "underscore";
import UA from "../lib/UA";

export default class SetViewport {
  constructor() {
    this.ua = new UA();
    this.init();
  }

  init() {
    this.bindEvents();
    this.setViewPort();
  }

  bindEvents() {
    // resize event
    const debouncedOnResize = _.debounce(() => {
      this.setViewPort();
    }, 600);
    window.addEventListener("resize", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
    window.addEventListener("orientationchange", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
  }

  setViewPort() {
    if (this.ua.device() == "tab") {
      if (window.outerWidth <= 1280) {
        const newViewPort = 'width=1280';
        document.querySelector("meta[name='viewport']").setAttribute("content", newViewPort);
      } else {
        const newViewPort = 'width=device-width';
        document.querySelector("meta[name='viewport']").setAttribute("content", newViewPort);
      }
    }
  }
}
