import RocSessionStorage from "./RocSessionStorage";

/**
 * 週間ランキングのタブ情報を管理する.
 *
 * 必要となった背景：
 * CMS資材からAndroidアプリ(v.2.1.7)の履歴情報にアクセスが出来ず、
 * ブラウザバックやURLパラメーターの変化を検知出来ない.
 * History APIを用いた実装が理想だが、画面描画に必要な情報をセッションストレージに保存して活用する改修を行った.
 *
 * https://redmine.bookoff.org/redmine/issues/44176
 * https://yafuoff.backlog.jp/view/BOC_ROC_FRONT-4477
 */
export default class RankingOfWeekTab {
  constructor() {
    this.sessionKey = 'rankingTabs';
    this.clearExclusionPathList = ['/used/','/new/','/s/','/list/ranking'];
    this.rocSessionStorage = new RocSessionStorage();
    this.init();
  }

  init() {
    this.clear();
  }

  save(rankingNo, genreId, type) {
    var rankingTabs = this.rocSessionStorage.load(this.sessionKey);
    rankingTabs[genreId + type] = rankingNo;
    this.rocSessionStorage.save(this.sessionKey, rankingTabs);
  }

  load() {
    return this.rocSessionStorage.load(this.sessionKey);
  }

  clear() {
    if (this.clearExclusionPathList.some(path => window.location.pathname.startsWith(path))) {
      return;
    }
    this.rocSessionStorage.clear(this.sessionKey);
  }

}
