import $ from "jquery";
import UserAgent from "./UserAgent";
import vh from "./vh";
import Modal from "./Modal";
import Refinement from "./Refinement";
import ModalRefinementSp from "./ModalRefinementSp";
import Accordion from "./Accordion";
import Recommend from "./Recommend";
import CartItem from "./CartItem";
import Tips from "./Tips";
import TabIndex from "./TabIndex";
import Anchor from "./Anchor";
import PageTop from "./PageTop";
import RadioReceivingMethod from "./RadioReceivingMethod";
import SelectEmpty from "./SelectEmpty";
import PcContentFixed from "./PcContentFixed";
import DeleteAddress from "./DeleteAddress";
import DeleteCredit from "./DeleteCredit";
import Breadcrumb from "./Breadcrumb";
import Showmore from "./Showmore";
import ToggleList from "./ToggleList";
import SortDropdown from "./SortDropdown";
import Orientation from "./Orientation";
import ImageBox from "./ImageBox";
import AccordionTable from "./AccordionTable";
import CartFloat from "./CartFloat";
import DiscountAlert from "./DiscountAlert";
import Categorymore from "./Categorymore";
import AllCheck from "./AllCheck";
import SetItems from "./SetItems";
import HoverItem from "./HoverItem";
import TogglePassword from "./TogglePassword";
import ArrangeHeight from "./ArrangeHeight";
import NoPaste from "./NoPaste";
import Agree from "./Agree";
import Tab from "./Tab";
import RadioProp from "./RadioProp";
import TopCategory from "./TopCategory";

$(() => {
  // UserAgent
  new UserAgent();

  // vh
  new vh();

  // Modal
  if (document.getElementsByClassName("js-modal").length) {
    new Modal();
  }

  // PC 絞り込み
  if (document.getElementsByClassName("js-refinement").length) {
    new Refinement();
  }

  // Modal 絞り込み
  if (document.getElementsByClassName("js-modalRefinementSp").length) {
    new ModalRefinementSp();
  }

  // CartItem
  if (document.getElementsByClassName("js-cartItem").length) {
    new CartItem();
  }

  // Accordion
  if (document.getElementsByClassName("js-accordion").length) {
    new Accordion();
  }

  // Recommend
  if (document.getElementsByClassName("js-recommendSlider").length) {
    new Recommend();
  }

  // Tips
  if (document.getElementsByClassName("js-tips").length) {
    new Tips();
  }

  // プルダウン空値の際にクラス付与
  if ($('select').length) {
    new SelectEmpty();
  }

  // 受取方法 チェックボックス
  if (document.getElementsByClassName("js-radioReceivingMethod").length) {
    new RadioReceivingMethod();
  }

  // お届け先の登録を解除
  if (document.getElementsByClassName("js-deleteAddressItem").length) {
    new DeleteAddress();
  }

  // クレジットカードの登録を解除
  if (document.getElementsByClassName("js-deleteCreditItem").length) {
    new DeleteCredit();
  }

  // PC 右カラム追従
  if (
    document.getElementsByClassName("js-pcContentFixed").length &&
    document.getElementsByClassName("js-pcContentFixedTarget").length
  ) {
    new PcContentFixed("js-pcContentFixed", "js-pcContentFixedTarget");
  }

   // SP パンくず
   if (document.getElementsByClassName('breadcrumbs').length) {
    new Breadcrumb();
  }

  // 続きを読む
  if (document.getElementsByClassName("js-showmore").length) {
    new Showmore();
  }

  // リスト／グリッド切替
  if (document.getElementsByClassName("js-toggleList").length) {
    new ToggleList();
  }

  // カテゴリもっと見る
  if (document.getElementsByClassName("js-categorymore").length) {
    new Categorymore();
  }

  // 絞り込みドロップダウン
  if (document.getElementsByClassName("js-sortDropdown").length) {
    new SortDropdown();
  }

  // 正方形フレーム内画像の縦横検出
  if (document.getElementsByClassName("js-orientation").length) {
    new Orientation();
  }

  // 長方形フレーム内画像の縦横検出
  if (document.getElementsByClassName("js-imageBox").length) {
    new ImageBox();
  }

  // 商品詳細もっと見る
  if (document.getElementsByClassName("js-accordion-table").length) {
    new AccordionTable();
  }

  // 商品詳細カートフロートエリア
  if (document.getElementsByClassName("js-cartFloat").length) {
    new CartFloat();
  }

  // 値下げのお知らせの設定／解除
  if (document.getElementsByClassName("js-discountAlertItem").length) {
    new DiscountAlert();
  }

  // 全チェック
  if (document.getElementsByClassName("js-allCheck").length) {
    new AllCheck();
  }

  // セット商品詳細
  if (document.getElementsByClassName("js-setItems").length) {
    new SetItems();
  }

  // 離れた要素同士をホバーで連動させる
  if (document.getElementsByClassName("js-hoverItem").length) {
    new HoverItem();
  }

  // パスワードの表示／非表示切り替え
  if (document.getElementsByClassName("js-toggle-password").length) {
    new TogglePassword();
  }

  // 高さ揃え
  if (document.getElementsByClassName("js-arrangeHeight").length) {
    new ArrangeHeight();
  }

  // ペースト禁止
  if (document.getElementsByClassName("js-noPaste").length) {
    new NoPaste();
  }

  // ラジオにチェックでテキストボックス活性
  if (document.getElementsByClassName("js-radioProp").length) {
    new RadioProp();
  }

  // 同意する
  if (document.getElementsByClassName("js-agree").length) {
    new Agree();
  }

  // タブ切り替え
  if (document.getElementsByClassName("js-tab").length) {
    new Tab();
  }

  // 販売トップカテゴリ「
  if (document.getElementsByClassName("js-topCategory").length) {
    new TopCategory();
  }

  // TabIndex
  new TabIndex();

  // Anchor
  new Anchor();

  // PageTop
  new PageTop();
});
