import _ from "underscore";
import $ from "jquery";
import ModalLib from "../lib/ModalLib";

export default class DiscountAlert {
  constructor() {
    this.container = document.getElementsByClassName('js-discountAlertItem');
    this.modal = document.getElementById("modal");
    this.overlay = this.modal.getElementsByClassName("modal__overlay")[0];
    this.targetItem;
    this.lockFlg = false;

    // lib
    this.modalLib = new ModalLib();

    this.init();
  }

  init() {
    this.setTabIndex();
    this.bindEvents();
  }

  setTabIndex() {
    // modal
    const modal = document.getElementsByClassName("modal__dialog");
    if (modal.length) {
      Array.prototype.forEach.call(modal, elm => {
        elm.tabIndex = "0";
        const modalCloseBtn = elm.getElementsByClassName('js-modalClose');
        const modalSubmitBtn = elm.getElementsByClassName('js-discountAlertSubmit');
        const modalCancelBtn = elm.getElementsByClassName('js-discountAlertCancel');
        if (modalCloseBtn.length) {
          Array.prototype.forEach.call(modalCloseBtn, elm => {
            elm.tabIndex = "0";
          });
        }
        if (modalSubmitBtn.length) {
          Array.prototype.forEach.call(modalSubmitBtn, elm => {
            elm.tabIndex = "0";
          });
        }
        if (modalCancelBtn.length) {
          Array.prototype.forEach.call(modalSubmitBtn, elm => {
            elm.tabIndex = "0";
          });
        }
      });
    }
  }

  bindEvents() {
    // modal open
    this.debouncedOnModalClick = _.debounce((container, elm) => {
      const id = elm.dataset.modal;
      if (id) {
        this.targetItem = container;
        this.modalLib.openModal(id).done(() => {
          this.lockFlg = false;
        });
      }
    }, 0);
    Array.prototype.forEach.call(this.container, elm => {
      const discountAlertModalBtn = elm.getElementsByClassName('js-discountAlertModal')[0];
      if (discountAlertModalBtn) {
        discountAlertModalBtn.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          if (this.lockFlg) return false;
          this.lockFlg = true;
          this.debouncedOnModalClick(elm, discountAlertModalBtn);
        });
      }
    });

    // close modal
    const closeModal = document.getElementsByClassName('js-modalClose');
    this.debouncedOnOverlayClick = _.debounce(() => {
      this.closeModal();
    }, 0);
    $(this.overlay).on("click", e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnOverlayClick();
    });
    if (closeModal.length) {
      Array.prototype.forEach.call(closeModal, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnOverlayClick();
        });
        elm.addEventListener("keypress", e => {
          // Enter
          if (e.keyCode === 13) {
            this.debouncedOnOverlayClick();
          }
        });
      });
    }

    // 値下げのお知らせメールを設定ボタン
    $('.js-discountAlertSubmit').on('click', e => {
      e.preventDefault();
      // operation.js 内の discountAlert関数に選択された要素を渡す
      if (typeof discountAlert !== 'undefined') {
        discountAlert(this.targetItem);
      }
    });

    // 値下げのお知らせメールを解除ボタン
    $('.js-discountAlertCancel').on('click', e => {
      e.preventDefault();
      // operation.js 内の discountAlert関数に選択された要素を渡す
      if (typeof discountAlertCancel !== 'undefined') {
        discountAlertCancel(this.targetItem);
      }
    });
  }

  closeModal() {
    if (this.lockFlg) return false;
    this.modalLib.closeModal();
  }
}
