import $ from "jquery";
import UA from "../lib/UA";

export default class UserAgent {
  constructor() {
    // lib
    this.ua = new UA();

    this.bind();
  }

  bind() {
    const getDevice = this.ua.device();

    const setDevice = () => {
      const ua = window.navigator.userAgent.toLowerCase();
      if (getDevice == 'sp') {
        if (
          ua.indexOf('iphone') !== -1 ||
          ua.indexOf('ipod') !== -1
        ) {
          $("html").addClass("iphone");
        }
        if (ua.indexOf('android') !== -1) {
          $("html").addClass("android");
        }
      } else if(getDevice == 'tab') {
        if (
          (ua.indexOf('macintosh') > -1 && 'ontouchend' in document) ||
          ua.indexOf('ipad') > 0
        ) {
          $("html").addClass("ipad");
        } else if (ua.indexOf('android') > 0) {
          $("html").addClass("android");
        }
      } else {
        $("html").addClass("others");
        if (
          ua.indexOf('msie') != -1 ||
          ua.indexOf('trident') != -1
        ) {
          $("html").addClass("ie");
        }
        if (ua.indexOf("edge") > -1) {
          $("html").addClass("edge");
        }
        else if (ua.indexOf("edg") > -1) {
          $("html").addClass("edg");
        }
        if (ua.indexOf('chrome') > -1) {
          $("html").addClass("chrome");
        }
        else if (ua.indexOf('safari') > -1) {
          $("html").addClass("safari");
        }
      }
    }

    setDevice();
  }
}
