import _ from "underscore";
import $ from "jquery";

export default class Anchor {
  constructor() {
    this.speed = 500;
    this.breakPoint = 768;
    this.posTopPc = 0;
    this.posTopSp = 0;
    this.init();
  }

  init() {
    // ページ読み込み時
    const hash = location.hash;
    if (hash) {
      if ($(hash).length && !$('.js-tabLink[href="'+hash+'"]').length) {
        _.delay(() => {
          const pos = $(hash).offset().top - 10;
          // $('body,html').animate({ scrollTop: pos }, this.speed, 'swing');
        }, 500);
      }
    }

    this.bindEvents();
  }

  bindEvents() {
    // click event
    const target = document.getElementsByClassName('js-anchor');
    if (target.length) {
      Array.prototype.forEach.call(target, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          const href = elm.attributes["href"].value;
          const target = $(href);
          if (target.length) {
            this.move(target, elm);
          }
        });
      });
    }
  }

  move(target, elm) {
    const pos = this.getPos(target, elm) - 10;
    $('body,html').animate({ scrollTop: pos }, this.speed, 'swing');
  }

  getPos(target, elm) {
    const offset = elm.getAttribute("data-offset");
    let pos;
    if (offset) {
      pos = target.offset().top + parseInt(offset);
    } else {
      pos = target.offset().top - this.posTopPc;
      if (window.innerWidth < this.breakPoint) {
        pos = target.offset().top - this.posTopSp;
      }
    }
    return pos;
  }
}
