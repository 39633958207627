import $ from "jquery";

export default class Showmore {
  constructor() {
    this.init();
  }

  init() {
    this.setTabIndex();
    this.bind();
  }

  setTabIndex() {
    $(".js-showmore").each(function(){
      const $btn = $(this).find(".js-showmore-btn");
      $btn.attr('tabindex', 0);
    });
  }

  bind() {
    $(".js-showmore").each(function(){
      const $this = $(this);
      const $btn = $this.find(".js-showmore-btn");
      $btn.on("click", function(){
        $this.addClass("-active");
      });
      $btn.keydown(function(e){
        if (e.keyCode == 13) {
          $this.addClass("-active");
        }
      });
    });
  }
}
