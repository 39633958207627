import $ from "jquery";

export default class AccordionTable {
  constructor() {
    this.bind();
  }

  bind() {
    $(".js-accordion-table").each(function(){
      const $this = $(this);
      const $btn = $this.find(".js-accordion-table-btn");
      const $table = $this.find(".js-tbody-hide")
      const $text = $btn.text();
      let flg = "close";

      $btn.on("click", function(){
        $(".js-tbody-hide").toggle(500);
        if (flg == "close") {
            $btn.text("閉じる");
            flg = "open";
        }else{
          $btn.text("もっと見る");
          flg = "close";
          $("html,body").animate({scrollTop:$(".productDetail").offset().top});
        }
      });
    });
  }

}
