import $ from "jquery";

export default class Tab {
  constructor() {
    this.init();
  }

  init() {
    this.bind();
    this.open();
  }
  bind() {
    var hash = location.hash;
    if (hash&& $('.js-tabLink[href="'+hash+'"]').length) {
      $('.js-tabLink').removeClass('-current');
      $('.js-tabLink[href="'+hash+'"]').addClass('-current');
      $('.js-tabContent').removeClass('-current');
      $('.js-tabContent').removeClass('-show');
      $('.js-tabContent'+hash).addClass('-current');
      $('.js-tabContent'+hash).addClass('-show');
      $('html,body').scrollTop(0);
    }
  }
  open() {
    $('.js-tab').each(function(){
      const $target = $(this);
      const $trigger = $target.find('.js-tabLink');
      const contents = '.js-tabContent';
      $trigger.on('click', function(e){
        e.preventDefault();
        const id = $(this).attr('href');
        const $content = $(contents + id);
        $trigger.removeClass('-current');
        $(this).addClass('-current');
        $target.find(contents).removeClass('-current');
        $content.addClass('-current');
        $target.find(contents).removeClass('-show');
        setTimeout(() => {
          $content.addClass('-show');
        }, 100);
        return false;
      });
    });
  }
}
