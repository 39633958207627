import $ from "jquery";

export default class ImageBox {
  constructor() {
    this.bind();
  }

  bind() {
    $('.js-imageBox').each(function(){
      const $target = $(this);
      const w = $target.data('width')?$target.data('width'):128;
      const h = $target.data('height')?$target.data('height'):183;
      var img = $('<img>');
      img.attr('src', $target.attr('src'));
      img.on('load', function() {
        const r = $target.width() / $target.height();
        if(r<=(w/h)){
          $target.addClass('-ct');
        }else{
          $target.addClass('-md');
        }
      });
    });
  }
}
