import _ from "underscore";

export default class vh {
  constructor() {
    this.bind();
  }

  bind() {
    function setDevice(){
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh+'px');
    }
    setDevice();

    // リサイズイベント処理
    const debouncedOnResize = _.debounce(() => {
      setDevice();
    }, 0);
    window.addEventListener("resize", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
  }
}
