export default class UA {
  constructor() {}

  device() {
    const ua = navigator.userAgent;

    if (
      ua.indexOf('iPhone') > 0 ||
      ua.indexOf('iPod') > 0 ||
      (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
    ) {
      return 'sp';
    } else if (
      (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) ||
      ua.indexOf('iPad') > 0 ||
      (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') == -1) ||
      ua.indexOf('A1_07') > 0 ||
      ua.indexOf('SC-01C') > 0 ||
      ua.indexOf('Nexus 7') > 0
    ) {
      return 'tab';
    } else {
      return 'other';
    }
  }
}
