import _ from "underscore";
import $ from "jquery";

export default class SetItems {
  constructor() {
    this.init();
  }

  init() {
    this.bindEvents();
    this.tab();
    this.resize();
  }

  bindEvents() {
    // resize event
    const debouncedOnResize = _.debounce(() => {
      this.resize();
    }, 200);
    window.addEventListener("resize", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
    window.addEventListener("orientationchange", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);

  }

  tab() {
    if(document.getElementsByClassName('js-setItemsTab').length) {
      $('.js-setItemsTab').each(function(){
        const mediaQuery = matchMedia('not all and (max-width: 767px)');
        const $target = $(this);
        const $list = $target.find('.js-setItemsTabList');
        const $trigger = $target.find('.js-setItemsTabLink');
        const contents = '.js-setItemsTabContent';

        $trigger.on('click', function(e){
          e.preventDefault();
          const id = $(this).attr('href');
          const $current = $target.find('.js-setItemsTabLink[href="'+id+'"]');
          const $content = $(contents + id);
          $trigger.removeClass('-current');
          $current.addClass('-current');
          $target.find(contents).removeClass('-current');
          $content.addClass('-current');
          $target.find(contents).removeClass('-show');
          setTimeout(() => {
            $content.addClass('-show');
          }, 100);
        });
        if ($list.get(0).scrollWidth>$target.width()) {
          $target.find('.js-setItemsTabControl').each(function(){
            if(mediaQuery.matches){
              const $this = $(this);
              const $list = $this.find('.js-setItemsTabList');
              const $prev = $this.find('.js-setItemsTabPrev');
              const $next = $this.find('.js-setItemsTabNext');
              $next.addClass('-0');
              $prev.on('click', function(e){
                e.preventDefault();
                console.log(0);
                $list.animate({ scrollLeft: 0 }, 300, 'swing');
              });
              $next.on('click', function(e){
                e.preventDefault();
                $list.animate({ scrollLeft: $list.get(0).scrollWidth-$target.width() }, 300, 'swing');
              });
            }
          });

          $list.each(function(){
            $(this).on('scroll', function(){
              $(this).addClass('-scroll');
              if(mediaQuery.matches){
                var x = $(this).scrollLeft();
                if(x>0){
                  $(this).parents('.js-setItemsTabControl').removeClass('-start');
                  if(x > $list.get(0).scrollWidth-$target.width()-1){
                    $(this).parents('.js-setItemsTabControl').addClass('-end');
                  }else{
                    $(this).parents('.js-setItemsTabControl').removeClass('-end');
                  }
                }else{
                  $(this).parents('.js-setItemsTabControl').addClass('-start');
                }
              }
            });
          });
        }else{
          $list.addClass('-scroll');
          $list.parents('.js-setItemsTabControl').addClass('-hide');
        }
      });
    }
  }

  resize() {
    const self = this;
    var resizeTimer = false;
    $(window).on('resize orientationchange', function(){
      if (resizeTimer > 0) {
        clearTimeout(resizeTimer);
      }

      resizeTimer = setTimeout(function() {
        self.tab();
        if (window.outerWidth > 767) {
          $('.js-setItems').find('.js-accordion').removeClass('-active');
          $('.js-setItems').find('.js-accordionContent').attr('style','');
        }
      }, 200);
    });
  }

}
