import $ from "jquery";

export default class PageTop {
  constructor() {
    this.speed = 500;
    this.init();
  }

  init() {

    this.bindEvents();
  }

  bindEvents() {
    // click event
    const target = $('.js-pagetop');
    if (target.length) {
      target.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('body,html').animate({ scrollTop: 0 }, this.speed, 'swing');
      });
    }

    const target2 = $('.js-pageTop');
    if (target2.length) {
      target2.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('body,html').animate({ scrollTop: 0 }, this.speed, 'swing');
      });
    }
  }
}
