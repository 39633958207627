import $ from 'jquery';

export default class TopCategory {
  constructor() {
    this.lockFlg = false;
    this.init();
  }

  init() {
    this.bind();
  }
  bind() {
    const self = this;
    $('.js-topCategory').each(function(){
      const $this = $(this);
      const $trigger = $this.find('.js-topCategoryTrigger');
      const $nav = $this.find('.js-topCategoryNav');
      const $contents = $this.find('.js-topCategoryContent');
      $trigger.on('click', function(e){
        e.preventDefault();
        if (self.lockFlg) return false;
        self.lockFlg = true;
        const $target = $(this);
        $('.js-topCategoryContent').attr('style','');
        $('.js-topCategoryLv3').attr('style','');
        if($target.hasClass('-active')){
          self.close();

        }else{
          $trigger.removeClass('-active');
          $target.addClass('-active');
          $contents.removeClass('-show');
          $contents.addClass('-hide');
          const $content = $('.js-topCategoryContent' + $target.attr('href'));
          if($nav.hasClass('-open')){
            $content.removeClass('-hide');
            $content.addClass('-show');
            self.setHeight($content);
            var h = $content.outerHeight();
            $nav.animate({
              'height': h+'px'
            },
            {
              duration: 300,
              easing: 'swing',
              queue: false,
              complete: function() {
                self.lockFlg = false;
              }
            });

          }else{
            //初回
            $content.slideDown(300, function(){
              $nav.addClass('-open');
              $content.removeClass('-hide');
              $content.addClass('-show');
              self.setHeight($content);
              $nav.height($content.outerHeight());
              self.lockFlg = false;
            });
          }
        }
        return false;
      });
    });
  }
  close() {
    const self = this;
    $('.js-topCategoryContent').hide();
    $('.js-topCategoryNav').slideUp(300,function() {
      $('.js-topCategoryNav').removeClass('-open');
      $('.js-topCategoryNav').attr('style','');
      $('.js-topCategoryTrigger').removeClass('-active');
      $('.js-topCategoryContent').removeClass('-show');
      $('.js-topCategoryContent').addClass('-hide');
      self.lockFlg = false;
    });
  }

  setHeight($content) {
    $content.find('.js-topCategoryList').each(function(){
      //レイアウト設定
      var num = $(this).find('.js-topCategoryLv3').length;
      if(num == 4){
        $(this).addClass('topCategory__nav__list--col4');
      }
      var items = 0;
      $(this).find('.js-topCategoryLv3').each(function(i){
        const $list = $(this);
        var len = $list.find('.js-topCategoryItem').length;
        var items_tmp;
        if(i==0 && num>2 && num!=4){
          items_tmp = Math.ceil(len/2);
          $list.addClass('-col');
        }else{
          if(num == 1){
            if(len<5) {
              $(this).addClass('-col1');
              items_tmp = len;
            }else if(len<9){
              $(this).addClass('-col2');
              items_tmp = Math.ceil(len/2);
            }else if(len<13){
              $(this).addClass('-col3');
              items_tmp = Math.ceil(len/3);
            }else{
              $(this).addClass('-col4');
              items_tmp = Math.ceil(len/4);
            }
          }else if(num == 2){
            items_tmp = Math.ceil(len/2);
            $list.addClass('-col');
          }else{
            items_tmp = len;
          }
        }
        if(items<items_tmp){
          items = items_tmp;
        }
      });
      $(this).find('.js-topCategoryLv3').height(items*(14*1.6+12));
    });

  }
}
