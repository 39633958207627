import _ from "underscore";

export default class SortDropdown {
  constructor() {
    this.container = document.getElementsByClassName("js-sortDropdown");
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.debouncedOnBtnClick = _.debounce(container => {
      if (container.classList.contains('-selected')) {
        this.closeList(container);
      } else {
        this.openList(container);
      }
    }, 0);

    Array.prototype.forEach.call(this.container, elm => {
      const btnElm = elm.getElementsByClassName("js-sortDropdownBtn")[0];
      const contentElm = elm.getElementsByClassName("js-sortDropdownContents")[0];

      if (btnElm) {
        // click event
        btnElm.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnBtnClick(elm);
        });

        // keypress event
        btnElm.addEventListener("keypress", e => {
          // Enter
          if (e.keyCode === 13) {
            this.debouncedOnBtnClick(elm);
          }
        });
      }

      if (contentElm) {
        const linkElm = contentElm.getElementsByTagName("a");
        if (linkElm.length) {
          const firstElm = linkElm[0];
          const lastLinkElm = linkElm[linkElm.length-1];

          firstElm.addEventListener("keydown", e => {
            // Tab key
            if (e.shiftKey && e.keyCode === 9) {
              this.closeList(elm);
            }
          });

          lastLinkElm.addEventListener("keydown", e => {
            // Tab key
            if (!e.shiftKey && e.keyCode === 9) {
              this.closeList(elm);
            }
          });
        }
      }
    });



    const touchEvent = 'ontouchstart' in window ? 'touchstart' : 'click';
    document.querySelector("body").addEventListener(touchEvent, e => {
      if (!e.target.closest(".js-sortDropdown")) {
        // 範囲外を選択
        this.closeListAll();
      }
    });
  }

  openList(container) {
    Array.prototype.forEach.call(this.container, elm => {
      const contentElm = elm.getElementsByClassName("js-sortDropdownContents")[0];
      if (container == elm) {
        elm.classList.add("-selected");
        contentElm.classList.add("-selected");
      } else {
        elm.classList.remove("-selected");
        contentElm.classList.remove("-selected");
      }
    });
  }

  closeList(container) {
    const contentElm = container.getElementsByClassName("js-sortDropdownContents")[0];
    container.classList.remove("-selected");
    contentElm.classList.remove("-selected");
  }

  closeListAll() {
    Array.prototype.forEach.call(this.container, elm => {
      const contentElm = elm.getElementsByClassName("js-sortDropdownContents")[0];
      elm.classList.remove("-selected");
      contentElm.classList.remove("-selected");
    });
  }
}
