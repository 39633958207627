import $ from "jquery";

export default class HoverItem {
  constructor() {
    this.bind();
  }

  bind() {
    const mediaQuery = matchMedia('not all and (max-width: 767px)');
    $('.js-hoverItem').each(function(){
      const $target = $(this);
      const $trigger = $target.find('.js-hoverItemLink');
      if(mediaQuery.matches){
        $trigger.on('mouseover', function() {
          $target.addClass('-hover');
        });
        $trigger.on('mouseout', function() {
          $target.removeClass('-hover');
        });
      }
      if($target.find('.js-orientation').length) {
        const $img = $target.find('.js-orientation');
        var img = $('<img>');
        img.attr('src', $img.attr('src'));
        img.on('load', function() {
          if($img.hasClass('-ls')){
            $target.addClass('-imgLs');
          }
        });
      }
    });
  }
}
