import _ from "underscore";
import $ from "jquery";

export default class Recommend {
  constructor() {
    this.lockFlg = false;
    this.init();
  }

  init() {
    const accordionElm = document.getElementsByClassName('js-accordion');
    if (accordionElm.length) {
      Array.prototype.forEach.call(accordionElm, elm => {
        if (elm.classList.contains('-active')) {
          const content = elm.nextElementSibling;
          if (content.classList.contains('js-accordionContent')) {
            $(content).show();
          }
        }
      });
    }

    this.bindEvents();
  }

  bindEvents() {
    this.debouncedOnClick = _.debounce(elm => {
      if (this.lockFlg) return false;
      this.lockFlg = true;

      const content = elm.nextElementSibling;
      if (content.classList.contains('js-accordionContent')) {
        if (elm.classList.contains('-active')) {
          elm.classList.remove('-active');
          $(content).slideUp(400, () => {
            this.lockFlg = false;
          });
        } else {
          elm.classList.add('-active');
          $(content).slideDown(400, () => {
            this.lockFlg = false;
            if($(content).find('.js-setItemsTab').length){
              const $target = $(content).find('.js-setItemsTab');
              const $list = $target.find('.js-setItemsTabList');
              if ($list.get(0).scrollWidth>$target.width()) {
                $list.removeClass('-scroll');
                $list.each(function(){
                  $(this).on('scroll', function(){
                    $(this).addClass('-scroll');
                  });
                });
              }else{
                $list.addClass('-scroll');
              }

            }
          });
        }
      }
    }, 0);

    const accordionElm = document.getElementsByClassName('js-accordion');
    if (accordionElm.length) {
      Array.prototype.forEach.call(accordionElm, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnClick(elm);
        });
        elm.addEventListener("keypress", e => {
          // Enter
          if (e.keyCode === 13) {
            this.debouncedOnClick(elm);
          }
        });
      });
    }
  }
}
