import $ from "jquery";

export default class SelectEmpty {
  constructor() {
    this.bind();
  }

  bind() {
    $('select').each(function(){
      if ($(this).val() !== ""){
        $(this).removeClass('-empty');
      } else {
        $(this).addClass('-empty');
      }
    });
    this.onChange();
  }

  onChange() {
    const Target = $('.-empty');
    Target.each(function(){
      $(this).on('change', function(){
        if ($(this).val() !== ""){
          $(this).removeClass('-empty');
        } else {
          $(this).addClass('-empty');
        }
      });
    });
  }
}
