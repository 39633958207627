import $ from "jquery";

export default class Agree {
  constructor() {
    this.elm = '.js-agree';
    this.bind();
  }

  bind() {
    var self = this;
    $(self.elm).each(function(){
      var $this = $(this);
      var $check = $this.find('.js-agree-checkbox');
      var $btn = $this.find('.js-agree-submit');
      self.change($check,$btn);
      $check.on('change', function(){
        self.change($check,$btn)
      });
    });
  }

  change($check,$btn) {
    if($check.prop('checked')){
      if($btn.prop("tagName") == 'BUTTON' || $btn.prop("tagName") == 'INPUT'){
        $btn.prop("disabled", false);
      }else{
        $btn.removeClass("-disabled");
      }
    }else{
      if($btn.prop("tagName") == 'BUTTON' || $btn.prop("tagName") == 'INPUT'){
        $btn.prop("disabled", true);
      }else{
        $btn.addClass("-disabled");
      }
    }
  }

}
