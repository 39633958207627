import $ from "jquery";

export default class ToggleList {
  constructor() {
    this.bind();
    this.breakPoint = 768;
  }

  bind() {
    const self = this;
    self.gridImg();
    $(".js-toggleList").each(function(){
      const url = location.href;
      const params = url.substring(url.indexOf("?"))
      const $this = $(this);
      const $btn = $this.find(".js-toggleListBtn");
      const $target = $(".js-toggleListTarget");
      $btn.on("click", function(e){
        e.preventDefault();
        e.stopPropagation();
        if (!$(this).hasClass("-active")) {
          self.activeToggle($(this),$target);
        }
      });
    });
  }

  activeToggle($btn,$target) {
    const type = $btn.data('type');
    if(type == 'grid'){
      $target.addClass('-grid');
    }else{
      $target.removeClass('-grid');
    }
    if($btn.hasClass('-active')){
      $(".js-toggleListBtn").addClass('-active');
      $btn.removeClass('-active');
    }else{
      $(".js-toggleListBtn").removeClass('-active');
      $btn.addClass('-active');
    }
  }

  gridImg() {
    $('.js-gridImg').each(function(){
      const $target = $(this);
      var img = $('<img>');
      img.attr('src', $target.attr('src'));
      img.on('load', function() {
        const r = $target.width() / $target.height();
        if(r<=(128/184)){
          $target.addClass('-ct');
        }else{
          $target.addClass('-md');
        }
      });
    });
  }
}
