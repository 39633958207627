import $ from "jquery";

export default class TogglePassword {
  constructor() {
    this.bind();
  }

  bind() {
    $(".js-toggle-password").each(function(){
      const $this = $(this);
      const $btn = $this.find(".js-toggle-password-btn");
      const $textbox = $this.find('.js-textbox');

      $btn.on("click", function(e){
        e.preventDefault();
        if (!$btn.hasClass("-active")) {
           $btn.addClass("-active");
           $textbox.attr('type','text');
        }else{
          $btn.removeClass("-active");
          $textbox.attr('type','password');
        }
      });


    });
  }

}
