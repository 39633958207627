import $ from "jquery";

export default class NoPaste {
  constructor() {
    this.bind();
  }

  bind() {
    $(document).on('paste','.js-noPaste', function(){
      return false;
    });
  }

}
