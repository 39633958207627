import $ from "jquery";

export default class CartFloat {
  constructor() {
    this.mq = window.matchMedia('only screen and (max-width: 767px)');
    this.scroll();
    this.resize();
  }

  scroll() {
    const self = this;
    $(window).on('scroll', function(){
      self.show();
    });
  }

  resize() {
    const self = this;
    var resizeTimer = false;
    $(window).on('orientationchange', function(){
      if (resizeTimer > 0) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(function() {
        // リサイズ完了時に一度だけ実行
        self.show();
      }, 200);
    });
  }

  show() {
    var y = $(window).scrollTop();
    var h = window.innerHeight;
    var trigger = '.js-cartFloatTarget'+ (this.mq.matches? 'SP' : 'PC');
    var start = $(trigger).offset().top;
    var end = $('#footer').offset().top - h;
    if(y>start){
      $('.js-cartFloat').addClass('-show');
      if(y>end){
        $('.js-cartFloat').removeClass('-show');
      }
    }else{
      $('.js-cartFloat').removeClass('-show');
    }
  }
}
