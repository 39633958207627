export default class Breadcrumb {
  constructor() {
    this.init();
  }

  init() {
    const container = document.getElementsByClassName('breadcrumbs')[0];

    if (container) {
      const posLeft = container.scrollWidth - container.clientWidth;
      container.scrollTo(posLeft, 0);
    }
  }
}
