import $ from "jquery";

export default class RadioProp {
  constructor() {
    this.elm = '.js-radioProp';
    this.bind();
  }

  bind() {
    var self = this;
    $(self.elm).each(function(){
      var $this = $(this);
      var $item = $this.find('.js-radioProp-item');
      $item.each(function(){
        var $radio = $(this).find('.js-radioProp-radio');
        var $text = $(this).find('.js-radioProp-text');
        var $input = $text.find('.formBlock__text').eq(0);
        $radio.on('change', function(){
          const $this = $(this);
          if($this.prop('checked')){
            $('.js-radioProp-text').find('input').prop('disabled', true);
            if($text.length){
              $input.prop('disabled', false);
            }
          }
        });
        if($text.length){
          $text.on('click', function(){
            $radio.prop('checked', true);
            $input.prop('disabled', false);
            $input.focus();
          });
        }
      });
    });
  }

}
