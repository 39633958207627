export default class TabIndex {
  constructor() {
    this.init();
  }

  init() {
    this.setTabIndex();
  }

  setTabIndex() {
    // modal
    const modal = document.getElementsByClassName('modal__inner');
    if (modal.length) {
      Array.prototype.forEach.call(modal, elm => {
        elm.tabIndex = "0";
        const modalCloseBtn = elm.getElementsByClassName('js-modalClose');
        if (modalCloseBtn.length) {
          Array.prototype.forEach.call(modalCloseBtn, elm => {
            elm.tabIndex = "0";
          });
        }
      });
    }

    // cart quantity
    const cartQuantityMinus = document.getElementsByClassName('js-cartQuantityMinus');
    const cartQuantityPlus = document.getElementsByClassName('js-cartQuantityPlus');
    if (cartQuantityMinus.length) {
      Array.prototype.forEach.call(cartQuantityMinus, elm => {
        elm.tabIndex = "0";
      });
    }
    if (cartQuantityPlus.length) {
      Array.prototype.forEach.call(cartQuantityPlus, elm => {
        elm.tabIndex = "0";
      });
    }

    // radio label
    const radioFocus = document.getElementsByClassName('js-radioFocus');
    if (radioFocus.length) {
      Array.prototype.forEach.call(radioFocus, elm => {
        if (!elm.disabled) {
          const nextElm = elm.nextElementSibling;
          if (nextElm) {
            if (nextElm.tagName == 'LABEL') {
              nextElm.addEventListener("keypress", e => {
                // Space
                if (e.keyCode === 32) {
                  elm.checked = true;
                  e.preventDefault();
                }
              });
            }
          }
        }
      });
    }

    // tips btn
    const tipsElm = document.getElementsByClassName('js-tips');
    if (tipsElm.length) {
      Array.prototype.forEach.call(tipsElm, elm => {
        elm.tabIndex = "0";
      });
    }

    // Accordion
    const accordionElm = document.getElementsByClassName('js-accordion');
    if (accordionElm.length) {
      Array.prototype.forEach.call(accordionElm, elm => {
        elm.tabIndex = "0";
      });
    }
  }
}
